import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const AuthorFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const Actions = props => {
  const { resource, currentSort, filterValues, exporter, basePath } = props
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={false}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={10000}
      />
    </TopToolbar>
  )
}

const AuthorList = props => (
  <List
    {...props}
    actions={<Actions />}
    filters={<AuthorFilter />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
)

export default AuthorList
